<template>
  <div class="RecoveryCaseDetail">
    <van-nav-bar title="追讨案例" left-arrow :safe-area-inset-top="true" @click-left="onClickLeft" :fixed="true"
      :border="false" :placeholder="true" v-if="is_navbar == 'true'"/>
    <div class="grey" v-if="!defaultPage"></div>
    <div class="recoveryCaseDetailCeneter" v-if="!defaultPage">
      <div class="recoveryCaseDetailTitle">
        {{ caseDetail.title }}
      </div>
      <div class="titleTime">
        {{ caseDetail.time }}
      </div>
      <!-- <div class="subtitle">
        <span class="subtitleLeft">写在前面：</span>
        <span class="subtitleRight">{{ caseDetail.subtitle }}</span>
      </div> -->
      <div class="subtitleImage">
        <img :src="caseDetail.subtitleImage" alt="" class="subtitleImg" />
      </div>
      <div class="caseItem" v-for="(item, index) in caseDetail.caseList" :key="index">
        <div class="caseItemSort" v-show="item.sort !== ''">
          {{ item.sort }}
        </div>
        <div class="caseItemTitle" v-show="item.title !== ''">
          {{ item.title }}
        </div>
        <!-- <div v-for="(imgItem, imgIndex) in item.imgs" :key="imgIndex">{{imgItem}}</div> -->
        <div v-for="(imgItem, imgIndex) in item.imgs" :key="imgIndex" class="imgItemContainer">
          <img alt="" class="imgItem" :src="imgItem" />
        </div>
      </div>
    </div>
    <div class="defaultPageContainer" v-if="defaultPage">
      <div class="imgContainer">
        <img src="../../static/images/defaultPage@2x.png" alt="" class="defaultPageImg" />
        <div class="defaultPageText">什么都没有哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from "@/api/security";
export default {
  name: "RecoveryCaseDetail",
  data() {
    return {
      id: "",
      caseDetail: {},
      defaultPage: false,
      is_navbar:true
    };
  },
  mounted() {
    this.id = Number(this.$route.query.id);
    let configDetail = {
      key: "litigation_cases",
    };
    this.is_navbar = this.$route.query.is_navbar
    this.requestGetConfig(configDetail);
    console.log(this.$route.query.is_navbar,this.id);
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async requestGetConfig(configDetail) {
      try {
        const result = await getConfig(configDetail);
        if (result.code === 20000 || result.status_code === 1000) {
          let content = JSON.parse(result.data.content);
          let caseDetail = content.find((item) => {
            return item.id === this.id;
          });
          if (this.caseDetail) {
            this.caseDetail = caseDetail;
            this.defaultPage = false;
          } else {
            this.caseDetail = {};
            this.defaultPage = true;
          }
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.RecoveryCaseDetail {
  .grey {
    width: 375px;
    height: 10px;
    background: #F7F7F7;
  }

  .recoveryCaseDetailCeneter {
    width: 375px;
    min-height: calc(100vh - 46px);
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;

    .recoveryCaseDetailTitle {
      // height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #444444;
      line-height: 28px;
      // padding-top: 10px;
    }

    .titleTime {
      height: 18px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      line-height: 18px;
      padding-top: 10px;
    }

    .subtitle {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #444444;
      line-height: 20px;
      padding-top: 16px;

      .subtitleLeft {
        font-weight: 500;
        letter-spacing: 1.2px;
      }

      .subtitleRight {
        font-weight: 400;
        letter-spacing: 1.2px;
      }
    }

    .subtitleImage {
      margin: 0 auto;
      width: 355px;
      // height: 180px;
      padding-top: 10px;
      // background: #D8D8D8;

      .subtitleImg {
        width: 100%;
        // height: 100%;
        border-radius: 12px;

      }
    }

    .caseItem {
      .caseItemSort {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 20px;
        padding-top: 12px;
      }

      .caseItemTitle {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        line-height: 20px;
        padding-top: 8px;
        letter-spacing: 1.2px;
      }

      .imgItemContainer {
        width: 355px;
        // height: 180px;
        padding-top: 12px;

        .imgItem {
          width: 100%;
          // height: 100%;
          border-radius: 12px;
        }
      }
    }
  }

  .defaultPageContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgContainer {
      width: 161px;
      height: 160px;

      .defaultPageImg {
        width: 100%;
        height: 100%;
      }

      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
